import * as React from "react";
import { Link } from "gatsby";
import Navigation from "../components/navigation";

export default function IndexPage() {
  return (
    <div className="font-sans text-base text-black">
      <Navigation />
      <div className="container py-4 px-4 lg:px-12 xl:px-24">
        <div className="flex flex-col md:flex-row mb-12">
          <div className="w-full font-raleway md:w-50 text-5xl uppercase flex">
            <div className="mb-4 md:mx-auto md:mb-0">
              <h1>STL</h1>
              <h1>Policy</h1>
              <h1>Project</h1>
            </div>
          </div>
          <div className="my-auto w-full md:w-50 flex">
            <h2 className="text-2xl font-raleway font-light md:mx-auto">
              Exploring the <strong className="font-bold">impact</strong> of{" "}
              <strong className="font-bold">policies</strong> in Saint Louis
              through <strong className="font-bold">data</strong> science and
              visualization
            </h2>
          </div>
        </div>
        <div>
          <h2 className="text-5xl font-raleway mb-4 text-center">Welcome!</h2>
          <p className="mb-8">
            This is definitely not a site yet, just the start of a project. Come
            back in the future though and hopefully it will be something more
            thought out and better designed. You can check out the one story
            (not really at all data related) below:
          </p>
          <p className="text-center">
            <Link
              to="/school-board-election-2021"
              className="font-bold text-blue-500 hover:text-black"
            >
              School Board Election 2021
            </Link>
          </p>
        </div>
        <div className="bg-gray-300"></div>
      </div>
    </div>
  );
}
