import React from "react";
import { Link } from "gatsby";

export default function Navigation() {
  return (
    <nav className="bg-black mb-8 shadow font-raleway">
      <div className="p-4 flex flex-row">
        <div>
          <Link to="/" className="text-xl text-white mr-6">
            STL Policy Project
          </Link>
        </div>
      </div>
    </nav>
  );
}
